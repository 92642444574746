//import { ThemeProvider } from '@emotion/react';
import React, { useMemo, useState, useEffect } from "react";
import { themeCreator } from './theme';
import { ThemeProvider } from '@mui/system';
import { darkPalette} from "./palette/darkPalette";
import {lightPalette} from "./palette/lightPalette";
import { dynamicPalette } from "./palette/dynamicPalette";
import componentsOverride from "./overrides";
import typography from "./typography";
import shadows from "./shadows";
import PropTypes from "prop-types";
import { CssBaseline } from '@mui/material';
import { ThemeProvider as MUIThemeProvider, createTheme, StyledEngineProvider } from '@mui/material/styles';
import {getTheme, saveTheme} from "../Utils/helper"
import { useSelector } from "react-redux";
import { lighten, darken } from "@mui/system/colorManipulator";

const ThemeProviderWrapper = ({children}) => {
  const selectedTheme =getTheme();
  
  const currTheme = useSelector((state) => state.main.themeState);
  
  const orgData = useSelector((state) => state.organization.orgData) || [];

  const dynamicPrimaryColor = orgData[0]?.colorCode ? orgData[0].colorCode : "#007bff";
  const dynamicSecondaryColor = orgData[0]?.colorCode ? orgData[0].colorCode : "#007bff";
  

  const themeName =  ((selectedTheme==="") ||  (selectedTheme===null))? 'light' : selectedTheme;
  const [themeMode, setThemeMode] = useState( themeName );

  // Utility to determine contrast color based on base color's brightness
const getContrastText = (color) => {
  
  const r = parseInt(color.substring(1, 3), 16);
  const g = parseInt(color.substring(3, 5), 16);
  const b = parseInt(color.substring(5, 7), 16);
  
  
  const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;
  let textColor = luminance > 140 ? '#000' : '#FFF'
  
  return textColor;
};

  const generateShades = (baseColor) => ({
      lighter: lighten(baseColor, 0.8),  // 80% lighter than the base color
      light: lighten(baseColor, 0.5),    // 50% lighter
      main: baseColor,                   // Base color
      dark: darken(baseColor, 0.3),      // 30% darker
      darker: darken(baseColor, 0.6),    // 60% darker
      contrastText: getContrastText(baseColor) // White for contrast on darker shades
  });

  const dynamicThemePalette = {
    ...dynamicPalette,
    dynamicPrimaryColor: dynamicPrimaryColor,
    dynamicSecondaryColor: dynamicSecondaryColor,
    primary: generateShades(dynamicPrimaryColor),
    secondary: generateShades(dynamicSecondaryColor),
    gradient: {
      0: lighten(dynamicPrimaryColor, 0.3),   // Lighter version of primary
      1: dynamicPrimaryColor,                 // Base primary color
      2: lighten(dynamicPrimaryColor, 0.3),   // Repeat lighter version for symmetry
      3: darken(dynamicPrimaryColor, 0.2),    // Darker version of primary
    },

  }
  
  const lightThemeOptions = useMemo(
    () => ({
      palette: lightPalette,
      shape: { borderRadius: 10 },
      typography,
      shadows,
      outlineColor : 'rgb(215, 217, 217)' 
    }),
    []
  );

  const darkThemeOptions = useMemo(
    () => ({
      palette: darkPalette,
      shape: { borderRadius: 10 },
      typography,
      shadows,
      outlineColor : 'rgb(74, 77, 78)' 
    }),
    []
  );

  const dynamicThemeOptions = {
    palette: dynamicThemePalette,
    shape: { borderRadius: 10 },
    typography,
    shadows,
    outlineColor : 'rgb(74, 77, 78)' //--ref-neutrals-n-70
  }
  
  const themeOptions = {
    light: lightThemeOptions,
    dark: darkThemeOptions,
    dynamic:dynamicThemeOptions,
  };

  const theme = createTheme(themeOptions[themeMode]);
  // theme.components = componentsOverride(theme);
  
  useEffect(() => {
    const currentTheme = getTheme();
  
    if(currentTheme === '' || currentTheme === null){
      setThemeMode('light');
      saveTheme('light');
    } else {
      setThemeMode(currentTheme);
    }
  }, []);

  useEffect(() =>{
    if(currTheme !== ""){
      setThemeMode(currTheme);
      saveTheme(currTheme);
    }
  }, [currTheme])
  
  
  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
};
ThemeProviderWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};
export default ThemeProviderWrapper;

