import produce from "immer";
import { constant } from "../constants";

// ==============================|| REDUCER User ||============================== //

const initState = () => ({
  errors: [],
  userData: [],
  userresponse: {},
  roleList: [],
});

export default (state = initState(), action) => {
  let data = [];
  switch (action.type) {
    case constant.USER_ERRORS:
      return { ...state, errors: action.data };
      case constant.INIT_RESET_USERCURD_RESPONSE:
        return { ...state, userresponse: {} };
        case constant.USERCURD_DATA_SUCCESS:
      return { ...state, userresponse: {success:true, message: action.data} };
      case constant.SET_USER_DATA:
        action.data.records.map((ele, indx) => {
            return data.push({
                id: indx + 1,
                username: ele.username === "" || null || undefined ? "-" : ele.username,
                firstname: ele.firstname === "" || null || undefined ? "-" : ele.firstname,
                lastname: ele.lastname,
                roleName: ele.role.name  === "" || null || undefined ? "-" : ele.role.name,
                roleId: ele.role.id,
                user_id: ele.id,
                orgname: ele.organization.orgname === "" || null || undefined ? "-" : ele.organization.orgname,
                orgId: ele.organization.id,
                email: ele.email === "" || null || undefined ? "-" : ele.email
            });
        });
      return { ...state, userData: data, totalCount: action.data.count};
    case constant.SET_ROLES_FOR_ORGTYPE:
      return { ...state, roleList: action.data };
    case constant.RESET_USER_STATE_INIT:
      return initState();
    default:
      return { ...state };
  }
};
