import PropTypes from 'prop-types';
import { createContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { constant } from "../constants";

import jwtDecode from 'jwt-decode';

const verifyToken = (serviceToken) => {
    if (!serviceToken) {
        return false;
    }
    const decoded = jwtDecode(serviceToken);
    return decoded.exp > Date.now() / 1000;
};

const setSession = (serviceToken) => {
    if (serviceToken) {
        localStorage.setItem('serviceToken', serviceToken);
        //axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    } else {
        localStorage.removeItem('serviceToken');
       // delete axios.defaults.headers.common.Authorization;
    }
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //
const JWTContext = createContext(null);

export const JWTProvider = ({ children }) => {
    const dispatch = useDispatch();
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const clearSession = () => {
        localStorage.removeItem('serviceToken');
        setIsLoggedIn(false);
    };

    useEffect(() => {
        const init = async () => {
            try {
                const serviceToken = window.localStorage.getItem('jwToken');
                // if (serviceToken && verifyToken(serviceToken)) {
                    if (serviceToken) {  
                    setSession(serviceToken);
                    dispatch({
                        type: constant.AUTH_VERIFICATION,
                        payload: {
                            isLoggedIn: true
                        }
                    });
                    setIsLoggedIn(true);
                } else {
                    clearSession();
                }
            } catch (err) {
                clearSession();
            }
        };

        init();
    }, [dispatch]);

    return (
        <JWTContext.Provider value={{isLoggedIn, clearSession}}>
            {children}
        </JWTContext.Provider>
    );
};

JWTProvider.propTypes = {
    children: PropTypes.node.isRequired
};

export default JWTContext;

