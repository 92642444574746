import { put, takeEvery } from "redux-saga/effects";
import { constant } from "../constants";

function* clearAllState() {
  yield put({ type: constant.RESET_AUTH_STATE_INIT });
  yield put({ type: constant.RESET_SETTINGS_STATE_INIT });
  yield put({ type: constant.RESET_ORG_STATE_INIT });
  yield put({ type: constant.RESET_USER_STATE_INIT });
}

function* mainSaga() {
  yield takeEvery(constant.RESET_STATE_INIT, clearAllState);
}

export default mainSaga;
