const createShadow = () => {
    return [
        'none', // No shadow
        '0px 2px 4px rgba(0, 0, 0, 0.1)',
        '1px 1px 4px 0px #00000033',
        '1px 1px 4px 0px rgba(0, 0, 0, 0.2)',
        '0px -2px 3px 0px rgba(0, 0, 0, 0.2037) inset',
        '-4px 2px 9px 0px rgba(0, 0, 0, 0.1)', //nav profile menu
        '0 2px 8px rgba(40, 90, 185, .1)',
        '0 2px 5px rgba(40,90,185,.1)',
        '0px 2px 6px rgba(40,90,185,.1)'
    ];
};
const shadows = createShadow();

export default shadows;