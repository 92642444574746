import { all } from "redux-saga/effects";
import authSage from "../middleware/auth";
import settingSaga from "../middleware/settings";
import organizationSaga from "../middleware/organization.saga";
import userSaga from "../middleware/User.saga";
import mainSaga from "../middleware/main";

function* rootSaga() {
    yield all([
      authSage(),
      settingSaga(),
      organizationSaga(),
      userSaga(),
      mainSaga()
    ]);
  }
  export default rootSaga;