import { Outlet } from 'react-router-dom';

// ==============================|| FLEX LAYOUT ||============================== //

const FlexLayout = () => (
    <>
        <Outlet />
    </>
);

export default FlexLayout;