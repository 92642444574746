import { PaletteOptions } from "@mui/material/styles";
import { lighten, darken } from "@mui/system/colorManipulator";
import { useSelector } from "react-redux";

// Utility to determine contrast color based on base color's brightness
const getContrastText = (color) => {
    
    const r = parseInt(color.substring(1, 3), 16);
    const g = parseInt(color.substring(3, 5), 16);
    const b = parseInt(color.substring(5, 7), 16);
    
    
    const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;
    let textColor = luminance > 140 ? '#000' : '#FFF'
    
    
    return textColor;
  };

// Utility function to generate shades of the base color


const generateShades = (baseColor) => ({
    lighter: lighten(baseColor, 0.8),  // 80% lighter than the base color
    light: lighten(baseColor, 0.5),    // 50% lighter
    main: baseColor,                   // Base color
    dark: darken(baseColor, 0.3),      // 30% darker
    darker: darken(baseColor, 0.6),    // 60% darker
    contrastText: getContrastText(baseColor)              // White for contrast on darker shades
});

const dynamicPrimaryColor = "#C73659"; 
const dynamicSecondaryColor = "#C73659";  


export const dynamicPalette = {

    common: { black: '#000', white: '#fff' },

   
    primary: generateShades(dynamicPrimaryColor),
    secondary: generateShades(dynamicSecondaryColor),

    // Fixed Colors (do not change)
    grey: {
        0: 'rgb(255, 255, 255)',
        50: 'rgb(247, 247, 247)',
        100: 'rgb(202, 205, 205)',
        200: 'rgb(176, 179, 180)',
        300: 'rgb(98, 103, 104)',
        400: 'rgb(74, 77, 78)',
        500: 'rgb(49, 52, 52)',
        600: 'rgb(74, 77, 78)',
        A100: 'rgba(215, 217, 217, 1)',
        A200: 'rgb(247, 247, 247)',
        A300: 'rgb(240, 240, 240)',
        A400: 'rgb(149, 154, 155)',
        A500: 'rgb(244, 244, 244)',
    },
    green: {
        0: 'rgb(235, 247, 241)',
        1: 'rgb(186, 228, 206)',
        2: 'rgb(37, 39, 39)',
        3: 'rgb(74, 77, 78)',
        4: 'rgb(255, 255, 255)',
        5: 'rgb(215, 217, 217)',
        6: 'rgb(230, 230, 230)',
        7: 'rgb(173, 173, 173)',
        8: 'rgb(203, 131, 95)',
    },
    blue: { main: 'rgba(133, 199, 255, 1)' },

    gradient: {
        0: lighten(dynamicPrimaryColor, 0.3),   // Lighter version of primary
        1: dynamicPrimaryColor,                 // Base primary color
        2: lighten(dynamicPrimaryColor, 0.3),   // Repeat lighter version for symmetry
        3: darken(dynamicPrimaryColor, 0.2),    // Darker version of primary
    },

    info: {
        lighter: 'rgb(245, 245, 245)',
        light: 'rgb(176, 179, 180)',
        main: 'rgb(49, 52, 52)',
        dark: 'rgb(52, 161, 255)',
        darker: 'rgb(25, 26, 26)',
        contrastText: 'rgb(12, 13, 13)',
    },
    success: {
        lighter: 'rgb(235, 247, 241)',
        light: 'rgb(137, 209, 171)',
        main: 'rgb(47, 142, 92)',
        dark: 'rgb(24, 71, 46)',
        darker: 'rgb(12, 36, 23)',
        contrastText: 'rgb(49, 52, 52)',
    },
    warning: {
        lighter: 'rgb(255, 250, 229)',
        light: 'rgb(255, 224, 102)',
        main: 'rgb(255, 204, 0)',
        dark: 'rgb(153, 122, 0)',
        darker: 'rgb(51, 41, 0)',
        contrastText: 'rgb(49, 52, 52)',
    },
    error: {
        lighter: 'rgb(247, 226, 224)',
        light: 'rgb(230, 169, 161)',
        main: 'rgb(173, 90, 79)',
        dark: 'rgb(92, 47, 41)',
        darker: 'rgb(52, 25, 22)',
        contrastText: '#fff',
        extraShade: 'rgb(221, 140, 129)',
    },

    // Divider and Background Colors
    divider: 'rgb(215, 217, 217)',
    background: {
        paper: '#fff',
        default: 'rgb(255, 255, 255)',
        neutral: 'rgb(176, 179, 180)',
        main: '#f6f9fb',
    },
    button: { primary : "#377dff"},

    // Text Colors
    text: {
        primary: 'rgb(49, 52, 52)',
        secondary: 'rgb(74, 77, 78)',
        disabled: 'rgb(202, 205, 205)',
    },

    // Action Colors
    action: {
        active: 'rgb(49, 52, 52)',
        hover: 'rgb(242, 242, 242)',
        selected: 'rgb(202, 205, 205)',
        disabled: 'rgb(202, 205, 205)',
        disabledBackground: 'rgb(176, 179, 180)',
        focus: 'rgb(202, 205, 205)',
        hoverOpacity: 0.08,
        disabledOpacity: 0.48,
    },
};
