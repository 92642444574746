import MockAdapter from 'axios-mock-adapter';
import menuItem from './menuItem.json';
import orgData from './organizationData.json';
import userData from './userData.json';
import roleList from './roleList.json';
import * as apiConstant from "../services/api.constant"




export const initializeAxiosMockAdapter = (instance) => {

    const mock = new MockAdapter(instance);
    mock.onGet(apiConstant.API_LOGIN).reply(() => getToken()); 
    mock.onGet("/menuItem").reply(() => getMenuItem());
    mock.onGet("/orgdata").reply(() => getorgData());
    mock.onGet("/userdata").reply(() => getuserData());
};

export const getToken = () => {
    return [200, 

        {
            "accessToken": "eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJzdXBlcmFkbWluIiwicm9sZUlkIjoxLCJyb2xlcyI6WyJTVVBFUl9BRE1JTiJdLCJleHAiOjE3MzA4ODgzMzgsInVzZXJJZCI6MTkzLCJpYXQiOjE3MzA4ODgwMzgsIm9yZ0lkIjoxfQ.Iqdi8n1I1EUaQyhxAKUELCx9fFwSYNLb5dP3B2uvFZE",
            "refreshToken": "eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJzdXBlcmFkbWluIiwiaWF0IjoxNzMwODg4MDM4LCJleHAiOjE3MzA4ODk4Mzh9.vlDnNiUsPyXxKLzzOCg4K-jID8AzRYiNJKeoF8JL3WQ"
        }
    ]
};

export const getMenuItem = () => {
    return [200, menuItem]
};

export const getorgData = () => {
    return [200, orgData]
};

export const getuserData = () => {
    return [200, userData]
};
export const getRoleList = () => {
    return [200, roleList]
};

export const getMenuList = (url) => {
    const roleId = url.charAt(url.length-1);
    switch(roleId){
        case "1": 
        return [200, menuItem]
        case "2": 
        return [200, menuItem]
    }
};