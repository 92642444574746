import APIService from "./api";
import {
  API_ROLE_ALL,
  ADMIN_ADD_ROLE,
  ADMIN_UPDATE_ROLE,
  ADMIN_DELETE_ROLE,
  ADMIN_ADD_MENU,
  ADMIN_UPDATE_MENU,
  ADMIN_DELETE_MENU,
  API_ROLEBASE_MENU,
  API_ASSIGN_MENU_ROLE,
  API_ORGTYPE_ALL,
  ADMIN_ADD_ORGTYPE,
  ADMIN_UPDATE_ORGTYPE,
  ADMIN_DELETE_ORGTYPE,
  API_MENU_ALL,
  API_GET_ORGTYPE_ROLE,
  API_ASSIGN_ORGTYPE_ROLE,
  API_UPLOAD_LOGO_THEME
} from "./api.constant";

const SettingAPI = {
  getRoleList() {
    return APIService.get(API_ROLE_ALL, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }).catch((err) => {
      throw err;
    });
  },
  getRoleBaseMenuList(roleID) {
    return APIService.get(
      API_ROLEBASE_MENU,
      {
        params: {
          roleId: roleID,
        },
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    ).catch((err) => {
      throw err;
    });
  },
  addNewRole(rolename) {
    const postData = {
      name: rolename,
    };
    return APIService.post(ADMIN_ADD_ROLE, postData, {
      headers: {
        "Content-Type": "application/json",
      },
    }).catch((err) => {
      throw err;
    });
  },
  editRole(roleId, roleName) {
    const data = {
      id: roleId,
      name: roleName,
    };
    return APIService.put(ADMIN_UPDATE_ROLE, data, {
      headers: {
        "Content-Type": "application/json",
      },
    }).catch((err) => {
      throw err;
    });
  },
  deleteRole(roleId) {
    return APIService.delete(
      ADMIN_DELETE_ROLE,
      {
        params: {
          id: roleId,
        },
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    ).catch((err) => {
      console.error(err) ;
    });
  },
  addNewMenu(menuname, componentName) {
    let menuUrl = menuname.toLowerCase().replace(/\s/g, "");
    menuUrl = "/" + menuUrl;
    const postData = {
      menuName: menuname,
      menuUrl: menuUrl,
      menuComponent: componentName,
    };
    return APIService.post(ADMIN_ADD_MENU, postData, {
      headers: {
        "Content-Type": "application/json",
      },
    }).catch((err) => {
      throw err;
    });
  },
  editMenu(menuId, menuName, menuComponent, menuUrl) {
    const data = {
      id: menuId,
      menuName: menuName,
      menuComponent: menuComponent,
      menuUrl: menuUrl,
    };
    return APIService.put(ADMIN_UPDATE_MENU, data, {
      headers: {
        "Content-Type": "application/json",
      },
    }).catch((err) => {
      throw err;
    });
  },
  deleteMenu(menuId) {
    return APIService.delete(
      ADMIN_DELETE_MENU,
      {
        params: {
          id: menuId,
        },
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    ).catch((err) => {
      console.error(err) ;
    });
  },
  assignMenuRole(data) {
    return APIService.put(API_ASSIGN_MENU_ROLE, data, {
      headers: {
        "Content-Type": "application/json",
      },
    }).catch((err) => {
      throw err;
    });
  },
  getOrgTypeList() {
    return APIService.get(API_ORGTYPE_ALL, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }).catch((err) => {
      throw err;
    });
  },
  addNewOrgType(type) {
    const postData = {
      name: type,
    };
    return APIService.post(ADMIN_ADD_ORGTYPE, postData, {
      headers: {
        "Content-Type": "application/json",
      },
    }).catch((err) => {
      throw err;
    });
  },
  editOrgType(orgId, editedOrgType) {
    const data = {
      id: orgId,
      name: editedOrgType,
    };
    return APIService.put(ADMIN_UPDATE_ORGTYPE, data, {
      headers: {
        "Content-Type": "application/json",
      },
    }).catch((err) => {
      throw err;
    });
  },
  deleteOrgType(orgId) {
    return APIService.delete(
      ADMIN_DELETE_ORGTYPE,
      {
        params: {
          id: orgId,
        },
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    ).catch((err) => {
      console.error(err) ;
    });
  },
  getOrgTypeRoleList(orgId) {
    return APIService.get(
      API_GET_ORGTYPE_ROLE,
      {
        params: {
          orgTypeId: orgId,
        },
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    ).catch((err) => {
      throw err;
    });
  },

  getMenuList() {
    return APIService.get(API_MENU_ALL, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }).catch((err) => {
      throw err;
    });
  },

  assignOrgTypeRole(data) {
    return APIService.put(API_ASSIGN_ORGTYPE_ROLE, data, {
      headers: {
        "Content-Type": "application/json",
      },
    }).catch((err) => {
      throw err;
    });
  },
  setThemeAndLogo(data) {
    //data to be inserted as formData
    console.log(data, "data 123")
    return APIService.post(API_UPLOAD_LOGO_THEME,data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }).catch((err) => {
      throw err;
    });
  },
};

export default SettingAPI;
