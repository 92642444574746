import LocalizedStrings from "react-localization";
import { getLanguage, saveLanguage } from "../../Utils/helper";
import en from "./English.json";
import ar from "./Arabic.json";
import fr from "./French.json"

const language = 'en'; 
const selectedLanguage=getLanguage();
export let strings = new LocalizedStrings({
  en,
  ar,
  fr
});


if(selectedLanguage!=="" && selectedLanguage!== null){
  strings.setLanguage(selectedLanguage);   
}else{
  strings.setLanguage(language);   
}

