import { PaletteOptions } from "@mui/material/styles";
import { lighten, darken } from "@mui/system/colorManipulator";

const LightPrimaryColor = "#377dff"; 
const LightSecondaryColor = "#377dff";


// Utility to determine contrast color based on base color's brightness
const getContrastText = (color) => {
    
  const r = parseInt(color.substring(1, 3), 16);
  const g = parseInt(color.substring(3, 5), 16);
  const b = parseInt(color.substring(5, 7), 16);
  
  
  const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;
  let textColor = luminance > 140 ? '#000' : '#FFF'
  
  
  return textColor;
};

// Utility function to generate shades of the base color


const generateShades = (baseColor) => ({
  lighter: lighten(baseColor, 0.8),  // 80% lighter than the base color
  light: lighten(baseColor, 0.5),    // 50% lighter
  main: baseColor,                   // Base color
  dark: darken(baseColor, 0.3),      // 30% darker
  darker: darken(baseColor, 0.6),    // 60% darker
  contrastText: '#000'             // White for contrast on darker shades
});



const GREY = {
  0: 'rgb(49, 52, 52)', 
  50: 'rgb(247, 247, 247)',
  100: 'rgb(123, 129, 130)', 
  200: 'rgb(123, 129, 130)', 
  300: 'rgb(149, 154, 155)',
  400: 'rgb(202, 205, 205)',
  500: 'rgb(229, 230, 230)',
  600: 'rgb(229, 230, 230)',  
  A100: 'rgba(74, 77, 78, 1)',
  A200: 'rgb(49, 52, 52)', 
  A300: 'rgb(49, 52, 52)',
  A400: 'rgb(149, 154, 155)',
  A500: 'rgba(74, 77, 78, 1)',
};

const GRADIENT = {
  0: '#8DFFFF',
  1: '#023FFA', 
  2: '#8DFFFF',
  3: '#023FFA',
};

const GREEN = {
  0: 'rgb(35, 72 , 54)', 
  1: 'rgb(137, 209, 171)',
  2: 'rgb(229, 230, 230)',
  3: 'rgb(215, 217, 217)',
  4: 'rgb(49, 52, 52)',
  5: 'rgb(74, 77, 78)',
  6: 'rgb(82, 82, 82)',
  7: 'rgb(173, 173, 173)',
  8: 'rgb(203, 131, 95)'
};

const SECONDARY = {
  lighter: 'rgb(16, 53, 84)', 
  light: 'rgb(52, 161, 255)', 
  main: 'rgb(229, 230, 230)', 
  dark: 'rgb(229, 230, 230)', 
  darker: 'rgb(229, 230, 230)', 
  contrastText: 'rgb(229, 230, 230)', 
};

const INFO = {
  lighter: 'rgb(49, 52, 52);', 
  light: 'rgb(123, 129, 130)', 
  main: 'rgb(0, 0, 0)',
  dark: 'rgb(52, 161, 255)', 
  darker: 'rgb(25, 26, 26)', 
  contrastText: 'rgb(12, 13, 13)',
};

const SUCCESS = {
  lighter: 'rgb(24, 71, 46 / 0.3)', 
  light: 'rgb(137, 209, 171)', 
  main: 'rgb(137, 209, 171)',  
  dark: 'rgb(24, 71, 46)', 
  darker: 'rgb(12, 36, 23', 
  contrastText: GREY[800],
};

const WARNING = {
  lighter: 'rgb(255, 250, 229)', 
  light: 'rgb(255, 224, 102)', 
  main: 'rgb(255, 204, 0)', 
  dark: 'rgb(153, 122, 0)', 
  darker: 'rgb(51, 41, 0)', 
  contrastText: GREY[800],
};

const ERROR = {
  lighter: 'rgb(247, 226, 224)', 
  light: 'rgb(230, 169, 161)',  
  main: 'rgb(221, 140, 129)', 
  dark: 'rgb(92, 47, 41)', 
  darker: 'rgb(52, 25, 22)', 
  contrastText: '#fff',
  extraShade : 'rgb(221, 140, 129)'
};

export const lightPalette = {
  common: { black: '#000', white: '#fff' },
  primary: generateShades(LightPrimaryColor),
    secondary: generateShades(LightSecondaryColor),
  info: { ...INFO },
  success: { ...SUCCESS },
  warning: { ...WARNING },
  error: { ...ERROR },
  blue: { main: 'rgba(133, 199, 255, 1)' },
  grey: GREY,
  green: GREEN,
  gradient: GRADIENT,
  divider: 'rgba(231,234,243,.7)', 
  text: { primary: '#000000', secondary: GREY[50], disabled: GREY[200] },
  // button: { primary : "#377dff"},
  background: { paper: 'rgb(255, 255, 255)', default:'rgb(255, 255, 255)' , neutral: "rgb(255, 255, 255)", main: "rgb(255, 255, 255)",header: "rgba(231,234,243,.7)" , highlight:"rgba(231, 234, 243, 0.907)", colHeader:"#e7eaf3b3", iconColor:"#b7b7b7"}, 
  onSurface : {
    main : 'rgb(16, 53, 84)' ,
    paper : "0 .1875rem .75rem rgba(140,152,164,.25)",
    
  },
  action: {
    active: GREY[500],
    hover: "#e7eaf3e7", // --hover
    selected: GREY[500],
    disabled: GREY[100],
    disabledBackground: GREY[200],
    focus: GREY[100],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};
