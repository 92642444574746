import axios from "axios";
import { getToken, getUserInfo, getRefreshToken, saveToken } from "../Utils";
import { toast } from "react-toastify";

const configValue = process.env.REACT_APP_API_BASE_URL;
const APIService = axios.create({
  baseURL: configValue,
  headers: {
    "Content-Type": "application/json",
  },
});

const refreshToken = async (token) => {
  const config = {
    headers: {
      "Authorization": `Bearer ${token}`
    }
  };
  const response = await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}api/v1/user/tokenrefresh`,
    config
  );
  return response.data;
}

APIService.interceptors.request.use(async (config) => {
 
  const token = getToken();
  const userInfo = getUserInfo();
  if (token == null || userInfo.exp > Date.now() / 1000) {
    config.headers.Authorization = token ? `Bearer ${token}` : "";
  } else {
    try {
      const refreshTokenValue = getRefreshToken();
      const Token = await refreshToken(refreshTokenValue);
      const accessToken = Token.accessToken;
      config.headers.Authorization = accessToken ? `Bearer ${accessToken}` : "";
      saveToken(accessToken,null,Token.refreshToken);
 
    } catch (error) {
      // If the refresh token is expired or invalid, log the user out
      localStorage.removeItem("jwToken");
      localStorage.removeItem("refreshToken");
      window.location.replace("/login");
    }
  }
  return config;
});

APIService.interceptors.response.use(
  response => response,
  error => {
    // if (error.response.status === 400 || error.response.status === 401 || error.response.status === 403 || error.response.status === 404) {
    //   toast.error(error.response.data.message, {
    //     toastId: 'showToast',
    //     position: toast.POSITION.TOP_CENTER,
    //     autoClose: 1500, 
    //   });
    // } else 
    if(error.response.status === 500){
      toast.error("Internal Server Error", {
        toastId: 'showToast',
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1500, 
      });
    } else{
      return Promise.reject(error);
    }
  }
);



export default APIService;
