import jwt_decode from "jwt-decode";
import { segmentCapture } from "../Utils/helper";
const JWTOKEN = "jwToken";
const userInfo = {
    userId: null,
    roleId: null,
    username: null,
    rolename: null
  };

export const getToken = () => {
    return window.localStorage.getItem(JWTOKEN);
}
export const getRefreshToken = () => {
    return window.localStorage.getItem('refreshToken');
}

export const getUserInfo = () => {
    const userInfoString = window.localStorage.getItem('userInfo');
    return JSON.parse(userInfoString);
}

export const saveToken = (token, username, refreshToken) => {
    const decoded_token = jwt_decode(token);
    console.log(decoded_token, "decoded_token 123")
    userInfo.userId = decoded_token.userId;
    userInfo.orgId = decoded_token.orgId;
    userInfo.orgName = decoded_token.orgName;
    userInfo.exp = decoded_token.exp;
    userInfo.roleId = decoded_token.roleId;
    userInfo.username = decoded_token.sub;
    userInfo.rolename = decoded_token.roles[0];
    window.localStorage.setItem(JWTOKEN, token);
    window.localStorage.setItem('refreshToken', refreshToken);
    window.localStorage.setItem('userInfo', JSON.stringify(userInfo));
    segmentCapture('identify', {
        userID: decoded_token.userId,
        params: {
            id: decoded_token.roleId,
            username: username,
            name: decoded_token.sub,
            title: decoded_token.roles[0]
        }
    })
};

export const deleteToken = (token) => {
    window.localStorage.removeItem(JWTOKEN);
    window.localStorage.removeItem('currentPath');
    window.localStorage.removeItem('serviceToken');
    window.localStorage.removeItem('languageMode');
    window.localStorage.removeItem('themeMode');
   // window.localStorage.removeItem('refreshToken');
};

export const responseMessageSave = () => {
    window.localStorage.setItem('messageInfo', 'true');
}
export const responseMessageDelete = () => {
    window.localStorage.removeItem('messageInfo');
}
export const getResponseMessage = () => {
    const ResponseMessage = window.localStorage.getItem('messageInfo');
    return ResponseMessage;
}

export default {
    getToken,
    saveToken,
    deleteToken
};