import React, { Component } from "react";
import { BrowserRouter } from "react-router-dom";
import ThemeProvider from "./theme/themeprovider";
import { LanguageProvider } from "./components/LanguageProvider";
import MainRoutes from "./Route/MainRoutes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { JWTProvider } from "./Utils/JWTContext";

import "./App.css";

function App() {
  return (
    <ThemeProvider>
      <LanguageProvider>
      <JWTProvider>
        <BrowserRouter>
          <MainRoutes />
        </BrowserRouter>
      </JWTProvider>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        newestOnTop={true}
      />
      </LanguageProvider>
      </ThemeProvider>
  );
}

export default App;
