import { constant } from "../constants";

export const postLogin = (formdata) => {
  return { type: constant.AUTH_LOGIN, data: formdata };
};
export const getRouter = () => {
  return { type: constant.FETCH_ROUTER_SUCCESS, data: "" };
};
export const getOrgdata = () => {
    return { type: constant.GET_ORG_DATA };
};

export const deleteOrg = (orgId) => {
  return { type: constant.ADMIN_DELETE_ORG, data: orgId };
};

export const getUserdata = (params) => {
    return { type: constant.GET_USER_DATA, data: params};
};
export const deleteUser = (userId) => {
  return { type: constant.ADMIN_DELETE_USER_REQ, data: userId };
};

export const getRoleList = () => {
  return { type: constant.FETCH_ROLE_REQUEST, data: "" };
};
export const getOrgTypeList = () => {
  return { type: constant.FETCH_ORGTYPE_REQUEST, data: "" };
};
export const getMenuList = () => {
  return { type: constant.FETCH_MENULIST_REQ, data: "" };
};
export const getMenuRoleBasedList = (roleID) => {
  return { type: constant.FETCH_ROLE_MENULIST_REQ, data: roleID };
};

export const addRole = (roleName) => {
  return { type: constant.ADMIN_ADD_ROLE, data: roleName };
};
export const editRole = (roleData) => {
  return { type: constant.ADMIN_EDIT_ROLE_REQ, data: roleData };
};
export const deleteRole = (roleId, roleName) => {
  return { type: constant.ADMIN_DELETE_ROLE_REQ, data: roleId, roleName };
};


export const addMenu = (menuName) => {
  return { type: constant.ADMIN_ADD_MENU, data: menuName };
};
export const editMenu = (menuData) => {
  return { type: constant.ADMIN_EDIT_MENU_REQ, data: menuData };
};
export const deleteMenu = (menuId) => {
  return { type: constant.ADMIN_DELETE_MENU_REQ, data: menuId };
};
export const assignMenu = (data) => {
  return { type: constant.MENU_ASSIGN_ROLE, data: data };
};


export const addOrgType = (value) => {
  return { type: constant.ADMIN_ADD_ORGTYPE, data: value };
};
export const editOrgType = (value) => {
  return { type: constant.ADMIN_EDIT_ORGTYPE_REQ, data: value };
};
export const deleteOrgType = (value) => {
  return { type: constant.ADMIN_DELETE_ORGTYPE_REQ, data: value };
};
export const getRolesForOrgType = (value) => {
  return { type: constant.GET_ROLES_FOR_ORGTYPE, data: value}
}
export const getOrgTypes = () => {
    return { type: constant.GET_ORG_TYPES }
}
export const getOrgTypeMenuList = (roleID) => {
  return { type: constant.FETCH_ORGTYPE_MENULIST_REQ, data: roleID };
};
export const assignTypeBasedRole = (data) => {
  return { type: constant.ORGTYPE_ASSIGN_ROLE, data: data };
};
export const setThemeAndLogo = (data) => {
  return { type: constant.SET_THEME_LOGO_REQ, data: data };
};
