import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from '@redux-saga/core';
import reducer from "./combineReducer";
import middlewareStore from "./middleStore";
import logger from 'redux-logger'

const sagamiddleware = createSagaMiddleware();

const store = configureStore({
    reducer: reducer,
    middleware: (getDefaultMiddleware) => [sagamiddleware, ...getDefaultMiddleware().concat(logger)],
});
sagamiddleware.run(middlewareStore);

export { store };