export const constant = {
    
    RESET_STATE_INIT : "RESET_STATE_INIT", // CLEAR ALL STATE 
    RESET_AUTH_STATE_INIT : "RESET_AUTH_STATE_INIT", // AUTH SAGA
    RESET_SETTINGS_STATE_INIT : "RESET_SETTINGS_STATE_INIT", // SETTINGS SAGA
    RESET_ORG_STATE_INIT : "RESET_ORG_STATE_INIT", // ORG SAGA
    RESET_USER_STATE_INIT : "RESET_USER_STATE_INIT", // USER SAGA

    AUTH_LOGIN : "AUTH_LOGIN",
    AUTH_REGISTER : "AUTH_REGISTER",
    AUTH_INIT : "AUTH_INIT",
    AUTH_ERRORS : "AUTH_ERRORS",
    AUTH_CLEAN : "AUTH_CLEAN",
    AUTH_CURRENT : "AUTH_CURRENT",
    AUTH_VERIFICATION : "AUTH_VERIFICATION",
    AUTH_SAVE : "AUTH_SAVE",

    FETCH_ROUTER_REQUEST : "FETCH_MENU_REQUEST",
    FETCH_ROUTER_SUCCESS : "FETCH_ROUTER_SUCCESS",
    FETCH_ROUTER_FAILURE : "FETCH_ROUTER_FAILURE",
    ENREDIRECT_INIT : "ENREDIRECT_INIT",
    ENREDIRECT_ACTION : "ENREDIRECT_ACTION",
    FETCH_ROLE_LIST : "FETCH_ROLE_LIST",
    ORGANIZATION_ERRORS : "ORGANIZATION_ERRORS",
    USER_ERRORS : "USER_ERRORS",
    
    FETCH_ROLE_REQUEST : "FETCH_ROLE_REQUEST",
    FETCH_ROLE_SUCCESS : "FETCH_ROLE_SUCCESS",
    FETCH_MENU_REQ : "FETCH_MENU_REQ",
    FETCH_MENU_RES : "FETCH_MENU_RES",
    FETCH_MENULIST_REQ : "FETCH_MENULIST_REQ",
    FETCH_MENULIST_RES : "FETCH_MENULIST_RES",

    //Role CRUD
    ADMIN_ADD_ROLE : "ADMIN_ADD_ROLE",
    ADMIN_ADD_ROLE_SUCC : "ADMIN_ADD_ROLE_SUCC",
    ADMIN_DELETE_ROLE_REQ : "ADMIN_DELETE_ROLE_REQ",
    ADMIN_DELETE_ROLE_SUCC : "ADMIN_DELETE_ROLE_SUCC",
    ADMIN_EDIT_ROLE_REQ : "ADMIN_EDIT_ROLE_REQ",
    ADMIN_EDIT_ROLE_SUCC : "ADMIN_EDIT_ROLE_SUCC",

    //Menu CRUD
    ADMIN_ADD_MENU : "ADMIN_ADD_MENU",
    ADMIN_ADD_MENU_SUCC : "ADMIN_ADD_MENU_SUCC",
    ADMIN_EDIT_MENU_REQ : "ADMIN_EDIT_MENU_REQ",
    ADMIN_DELETE_MENU_REQ : "ADMIN_DELETE_MENU_REQ",
    MENU_ASSIGN_ROLE : "MENU_ASSIGN_ROLE",

    // Assign Action
    FETCH_ROLE_MENULIST_REQ : "FETCH_ROLE_MENULIST_REQ",
    FETCH_ROLE_MENULIST_RES : "FETCH_ROLE_MENULIST_RES",

    //Organization
    GET_ORG_DATA : "GET_ORG_DATA",
    SET_ORG_DATA : "SET_ORG_DATA",
    UPDATE_ORG_DATA : "UPDATE_ORG_DATA",
    CREATE_ORG_DATA : "CREATE_ORG_DATA",
    ORGCURD_DATA_SUCCESS : "ORGCURD_DATA_SUCCESS",
    RESET_ORGCURD_RESPONSE : "RESET_ORGCURD_RESPONSE",
    INIT_RESET_ORGCURD_RESPONSE : "RESET_ORGCURD_RESPONSE",
    GET_ORG_TYPES : "GET_ORG_TYPES",
    ADMIN_DELETE_ORG: "ADMIN_DELETE_ORG",
    SET_ORG_TYPES : "SET_ORG_TYPES",
    //Org Type
    FETCH_ORGTYPE_REQUEST : "FETCH_ORGTYPE_REQUEST",
    FETCH_ORGTYPE_SUCCESS : "FETCH_ORGTYPE_SUCCESS",
    ADMIN_ADD_ORGTYPE : "ADMIN_ADD_ORGTYPE",
    ADMIN_ADD_ORGTYPE_SUCC : "ADMIN_ADD_ORGTYPE_SUCC",
    ADMIN_EDIT_ORGTYPE_REQ : "ADMIN_EDIT_ORGTYPE_REQ",
    ADMIN_EDIT_ORGTYPE_SUCC : "ADMIN_EDIT_ORGTYPE_SUCC",
    ADMIN_DELETE_ORGTYPE_REQ : "ADMIN_DELETE_ORGTYPE_REQ",
    ADMIN_DELETE_ORGTYPE_SUCC : "ADMIN_DELETE_ORGTYPE_SUCC",
    FETCH_ORGTYPE_MENULIST_REQ : "FETCH_ORGTYPE_MENULIST_REQ",
    FETCH_ORGTYPE_MENULIST_RES : "FETCH_ORGTYPE_MENULIST_RES",
    ORGTYPE_ASSIGN_ROLE : "ORGTYPE_ASSIGN_ROLE",

    //User
    GET_USER_DATA : "GET_USER_DATA",
    SET_USER_DATA : "SET_USER_DATA",
    UPDATE_USER_DATA : "UPDATE_USER_DATA",
    CREATE_USER_DATA : "CREATE_USER_DATA",
    USERCURD_DATA_SUCCESS : "USERCURD_DATA_SUCCESS",
    RESET_USERCURD_RESPONSE : "RESET_USERCURD_RESPONSE",
    INIT_RESET_USERCURD_RESPONSE : "INIT_RESET_USERCURD_RESPONSE",
    GET_ROLES_FOR_ORGTYPE : "GET_ROLES_FOR_ORGTYPE",
    SET_ROLES_FOR_ORGTYPE : "SET_ROLES_FOR_ORGTYPE",
    ADMIN_DELETE_USER_REQ : "ADMIN_DELETE_USER_REQ",

    //Change theme and language
    CHANGE_THEME: "CHANGE_THEME",
    CHANGE_LANGUAGE: "CHANGE_LANGUAGE",
    SET_LANGUAGECODE_REQ: "SET_LANGUAGECODE_REQ",
    SET_LANGUAGECODE_RES: "SET_LANGUAGECODE_RES",

    //Theme and logo settings 
    SET_THEME_LOGO_REQ : "SET_THEME_LOGO_REQ",
    SET_THEME_LOGO_RES : "SET_THEME_LOGO_RES",
}