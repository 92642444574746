import React, { useEffect, useState, lazy, Suspense } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useRoutes, useNavigate } from "react-router-dom";
import { isEqual } from "lodash";
import useAuth from "../hooks/authHook";
import { constant } from "../constants";
import Loadable from "../components/Ui-Component/Loadable";
import CircularProgress from "@mui/material/CircularProgress";
import FlexLayout from "../layout/FlexLayout";
import { Box } from "@mui/material";

const MainLayout = Loadable(lazy(() => import("../layout/MainLayout")));
const AuthLogin = Loadable(lazy(() => import("../components/Login/login")));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = () => {
  const [routes, setRoutes] = useState([]);
  const { isLoggedIn } = useAuth();
  const isStateAuthenticated = useSelector(
    (state) => state.auth.isAuthenticated
  );
  const currLang = useSelector((state) => state.main.languageState);
  const isEnableredirect = useSelector((state) => state.auth.isEnableredirect);
  const routerData = useSelector((state) => state.auth.routerData) || [];
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const currentPath = localStorage.getItem("currentPath");
    if (isStateAuthenticated && isEnableredirect) {
      if (
        currentPath &&
        routes.some((route) => route.path === currentPath) &&
        currentPath != "/"
      ) {
        navigate(currentPath);
        dispatch({ type: constant.ENREDIRECT_INIT, data: false });
      } else if (routes.length > 1) {
        navigate(routes[1].path);
        dispatch({ type: constant.ENREDIRECT_INIT, data: false });
      }
    }

    if (isLoggedIn && !isStateAuthenticated) {
      dispatch({ type: constant.FETCH_MENU_REQ, data: ""});
    }
  }, [isStateAuthenticated, navigate, isLoggedIn, routes, isEnableredirect]);

  useEffect(() => {
    const loginRoute = {
      path: "/",
      element: <AuthLogin />,
    };
    const routesData = routerData.map((route) => {
      const Element = lazy(() => import(`../View/${route.element}`));
      return {
        path: route.path,
        element: (
          <Suspense
            fallback={
              <Box
                sx={{
                  position: "relative",
                  minHeight: "calc(100vh - 200px)", // subtract header height from viewport height
                }}
              >
                <Box
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                  }}
                >
                  <CircularProgress sx={{ margin: "auto", display: "block" }} />
                </Box>
              </Box>
            }
          >
            <Element />
          </Suspense>
        ),
        exact: route.exact,
      };
    });
    const updatedRoutes = [loginRoute, ...routesData];
    if (!isEqual(updatedRoutes, routes)) {
      setRoutes(updatedRoutes);

      // Save current path to localStorage
      const currentPath = window.location.pathname;
      localStorage.setItem("currentPath", currentPath);
    }
  }, [routerData]);

  const Layout = isStateAuthenticated ? MainLayout : FlexLayout;

  const routing = useRoutes([
    {
      path: "/",
      element: <FlexLayout />,
      children: [
        {
          path: "",
          element: <AuthLogin />,
        },
      ],
    },
    {
      path: "/",
      element: <Layout />,
      children: routes,
    },
  ]);
  return routing;
};

export default MainRoutes;
