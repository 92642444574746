import { PaletteOptions } from "@mui/material/styles";
import { lighten, darken } from "@mui/system/colorManipulator";

const DarkPrimaryColor = "#000"; 
const DarkSecondaryColor = "#000";

// Utility to determine contrast color based on base color's brightness
const getContrastText = (color) => {
    
  const r = parseInt(color.substring(1, 3), 16);
  const g = parseInt(color.substring(3, 5), 16);
  const b = parseInt(color.substring(5, 7), 16);
  
  
  const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;
  let textColor = luminance > 140 ? '#000' : '#FFF'
  
  
  return textColor;
};

// Utility function to generate shades of the base color


const generateShades = (baseColor) => ({
  lighter: lighten(baseColor, 0.8),  // 80% lighter than the base color
  light: lighten(baseColor, 0.5),    // 50% lighter
  main: baseColor,                   // Base color
  dark: darken(baseColor, 0.3),      // 30% darker
  darker: darken(baseColor, 0.6),    // 60% darker
  contrastText: "#c5c8cc"              // White for contrast on darker shades
});




const GREY = {
  0: 'rgb(49, 52, 52)', //surface
  50: 'rgb(247, 247, 247)',
  100: 'rgb(123, 129, 130)', // --on-neutral5
  200: 'rgb(123, 129, 130)', // on-neutrals4
  300: 'rgb(149, 154, 155)',//--on-neutral3
  400: 'rgb(202, 205, 205)',//--on-neutral2
  500: 'rgb(229, 230, 230)',//on-surface
  600: 'rgb(229, 230, 230)',  //--on-neutral1
  A100: 'rgba(74, 77, 78, 1)',//--outline
  A200: 'rgb(49, 52, 52)', // custom color popup / Review page
  A300: 'rgb(49, 52, 52)',// custom color grey
  A400: 'rgb(149, 154, 155)',
  A500: 'rgba(74, 77, 78, 1)',
};

const GRADIENT = {
  0: '#8DFFFF',//surface
  1: '#023FFA', //outline
  2: '#8DFFFF',
  3: '#023FFA',
};

const GREEN = {
  0: 'rgb(35, 72 , 54)', 
  1: 'rgb(137, 209, 171)',//surface
  2: 'rgb(229, 230, 230)',
  3: 'rgb(215, 217, 217)',
  4: 'rgb(49, 52, 52)',
  5: 'rgb(74, 77, 78)',
  6: 'rgb(82, 82, 82)',
  7: 'rgb(173, 173, 173)',
  8:'rgb(203, 131, 95)'
};
const SECONDARY = {
    lighter: 'rgb(16, 53, 84)', // --ref-primary-blue-85
    light: 'rgb(229, 230, 230)', //--ref-primary-blue-50
    main: 'rgb(93, 180, 255)', // --ref-primary-blue-40
    dark: 'rgb(229, 230, 230)', // --ref-neutrals-n-10
    darker: 'rgb(229, 230, 230)', // --ref-neutrals-n-10
    contrastText: 'rgb(229, 230, 230)', // --ref-neutrals-n-10
  };
  
  const INFO = {
    lighter: 'rgb(49, 52, 52);', // --ref-neutrals-n-05    --surface - 4
    light: 'rgb(123, 129, 130)', // --ref-neutrals-n-30
    main: "#c5c8cc", // --ref-neutrals-n-00
    dark: 'rgb(52, 161, 255)', //--ref-primary-blue-50
    darker: 'rgb(25, 26, 26)', // --ref-neutrals-n-90
    contrastText: '#c5c8cc', // --ref-neutrals-n-95
  };
  
  const SUCCESS = {
    lighter: 'rgb(24, 71, 46 / 0.3)', // --ref-green-g-05
    light: 'rgb(137, 209, 171)', // --ref-green-g-60
    main: 'rgb(137, 209, 171)',  // --ref-green-g-30
    dark: 'rgb(24, 71, 46)', // --ref-green-g-80
    darker: 'rgb(12, 36, 23', // --ref-green-g-90
    contrastText: GREY[800],
  };
  
  const WARNING = {
    lighter: 'rgb(255, 250, 229)', // --ref-yellows-y-05
    light: 'rgb(255, 224, 102)', // --ref-yellows-y-30
    main: 'rgb(255, 204, 0)', // --ref-yellows-y-50
    dark: 'rgb(153, 122, 0)', // --ref-yellows-y-70
    darker: 'rgb(51, 41, 0)', // --ref-yellows-y-90
    contrastText: GREY[800],
  };

  const ERROR = {
    lighter: 'rgb(247, 226, 224)', // --ref-red-r-10
    light: 'rgb(230, 169, 161)',  // --ref-red-r-30
    main: 'rgb(221, 140, 129)', // --ref-red-r-40
    dark: 'rgb(92, 47, 41)', // --ref-red-r-80
    darker: 'rgb(52, 25, 22)', // --ref-red-r-90
    contrastText: '#fff',
    extraShade : 'rgb(221, 140, 129)'
  };

export const darkPalette = {
    common: { black: '#000', white: '#fff' },
    primary: generateShades(DarkPrimaryColor),
    secondary: generateShades(DarkSecondaryColor),
    info: { ...INFO },
    success: { ...SUCCESS },
    warning: { ...WARNING },
    error: { ...ERROR },
    blue: { main: 'rgba(133, 199, 255, 1)' },
    grey: GREY,
    green: GREEN,
    gradient: GRADIENT,
    divider: '#5b5b5b', // --ref-neutrals-n-10 --outline
    text: { primary: '#c5c8cc', secondary: GREY[50], disabled: GREY[200] },
    background: { paper: 'rgb(37, 39, 39)', default:'#1e2022'  , neutral: "#25282a", main: "rgb(37, 39, 39)", header: "rgba(231,234,243,.7)",highlight:"rgba(231, 234, 243, 0.151)", colHeader:"#484848", iconColor:"rgba(231,234,243,.7)"}, //on-background
    onSurface : {
      main : 'rgb(16, 53, 84)' ,//--surface-2 --ref-primary-blue-85
      paper: "0 .1875rem .75rem rgba(140,152,164,.25)",
      
   },
    action: {
      active: GREY[500],
      hover:"#e7eaf3e7" , // --hover
      selected: GREY[500],
      disabled: GREY[100],
      disabledBackground: GREY[200],
      focus: GREY[100],
      hoverOpacity: 0.08,
      disabledOpacity: 0.48,
    },
};


