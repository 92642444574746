import { constant } from "../constants";

const initState = () => ({
  isAuthenticated: true,
  errors: [],
  roleList: [],
  menuList: [],
  roleMenuList: [],
  orgTypeList: [],
  orgTypeRoleList: [],
  logoAndThemeRes: null
});

export default (state = initState(), action) => {
  switch (action.type) {
    case constant.FETCH_ROLE_REQUEST:
      return { ...state, errors: [], isLoading: true };
    case constant.FETCH_ROLE_SUCCESS:
      return { ...state, roleList: action.data, errors: [] };
    case constant.FETCH_ROLE_MENULIST_REQ:
      return { ...state, errors: [], isLoading: true };
    case constant.FETCH_ROLE_MENULIST_RES:
      return { ...state, roleMenuList: action.data, errors: [] };
    case constant.FETCH_MENULIST_REQ:
      return { ...state, errors: [], isLoading: true };
    case constant.FETCH_MENULIST_RES:
      return { ...state, menuList: action.data, errors: [] };
    case constant.FETCH_ORGTYPE_SUCCESS:
      return { ...state, orgTypeList: action.data, errors: [] };
    case constant.FETCH_ORGTYPE_MENULIST_RES:
      return { ...state, orgTypeRoleList: action.data, errors: [] };
    case constant.RESET_SETTINGS_STATE_INIT:
      return initState();
    case constant.SET_THEME_LOGO_RES:
      return { ...state, logoAndThemeRes: action.data, errors: [] };
    default:
      return state;
  }
};
