
import React, { createContext, useEffect } from "react";
import { strings } from "../../constants/Localization";
import { useSelector, useDispatch } from "react-redux";
import { getLanguage, saveLanguage } from "../../Utils/helper";
import { constant } from "../../constants/index"; 

export const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const currLang = useSelector((state) => state.main.languageState);
  const dispatch = useDispatch();

  useEffect(() => {

    const savedLanguage = getLanguage();
    if (!currLang && savedLanguage) {
      strings.setLanguage(savedLanguage);
      dispatch({ type: constant.CHANGE_LANGUAGE, data: savedLanguage });
    } else if (currLang) {
      saveLanguage(currLang);
      strings.setLanguage(currLang);
    }
  }, [currLang, dispatch]);

  return (
    <LanguageContext.Provider value={strings}>
      {children}
    </LanguageContext.Provider>
  );
};
