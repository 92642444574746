export const API_LOGIN = "/api/v1/user/login";
export const API_SIGN_UP = "/signup";
export const API_MENU_ALL = "/api/v1/menu/getall";
export const API_MENU_ALL_LIST = "api/v1/menu/orgmenu/get";
export const API_ROLE_ALL = "/api/v1/role/getall";
export const API_ORGTYPE_ALL = "/api/v1/orgtype/getall";
export const ADMIN_ADD_ROLE = "/api/v1/role/save";
export const ADMIN_UPDATE_ROLE = "/api/v1/role/update";
export const ADMIN_DELETE_ROLE = "/api/v1/role/delete";
export const ADMIN_ADD_MENU = "/api/v1/menu/save";
export const ADMIN_UPDATE_MENU = "/api/v1/menu/update";
export const ADMIN_DELETE_MENU = "/api/v1/menu/delete";
export const ADMIN_ADD_ORGTYPE = "/api/v1/orgtype/save";
export const ADMIN_UPDATE_ORGTYPE = "/api/v1/orgtype/update";
export const ADMIN_DELETE_ORGTYPE = "/api/v1/orgtype/delete";
export const API_ROLEBASE_MENU = "/api/v1/menu/orgmenu/get";
export const API_GET_ORG = "api/v1/organization/getall";
export const API_UPDATE_ORGANIZATION = "api/v1/organization/update";
export const API_CREATE_ORGANIZATION = "api/v1/organization/save";
export const API_DELETE_ORGANIZATION = "api/v1/organization/delete";
export const API_ASSIGN_MENU_ROLE = "api/v1/menu/orgmenu/update";
export const API_GET_ORG_ID = "api/v1/organization/get";
export const API_GET_ROLES_FOR_ORGTYPE = "api/v1/orgtype/roles/getbyid";
export const API_GET_ALL_USER = "api/v1/user/getallusers";
export const API_CREATE_USER = "api/v1/user/create";
export const API_GET_ADMIN_ORG_USER = "api/v1/user/getadminorgusers";
export const API_UPDATE_USER = "api/v1/user/update";
export const API_GET_ORG_TYPES = "api/v1/orgtype/getall";
export const API_GET_ORGTYPE_ROLE = "/api/v1/orgtype/roles/getbytype";
export const API_ASSIGN_ORGTYPE_ROLE = "/api/v1/orgtype/roles/update";
export const API_DELETE_USER = "api/v1/user/delete";
export const API_UPLOAD_LOGO_THEME = "api/v1/user/upload-logo";