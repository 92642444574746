import { put, takeEvery } from "redux-saga/effects";
import { constant } from "../constants";
import SettingAPI from "../services/setting.service";
import { toast } from "react-toastify";
import { showError,toCamelCase } from "../Utils/helper";


function* setAuthError(data) {
  showError(data);
  return false;
}

function* getRoleList() {
  try {
    const rolesResponse = yield SettingAPI.getRoleList(),
    roleListItems = rolesResponse;
    if(roleListItems === undefined) return;
    const rolesData = roleListItems.data;
    if (!rolesData?.error) {
      const mappedRoleList = rolesData.records.map((roleList) => ({
        
        roleName: toCamelCase(roleList.name),
        roleId: roleList.id,
      }));
      yield put({ type: constant.FETCH_ROLE_SUCCESS, data: mappedRoleList });
    } else {
      yield setAuthError(rolesData.message);
    }
  } catch (e) {
    yield setAuthError(e.message);
    console.error(e);
  }
}
function* getOrgTypeList() {
  try {
    const response = yield SettingAPI.getOrgTypeList(),
    responseItems = response;
    if(responseItems === undefined) return;
    const orgTypeData = responseItems.data;
    if (!orgTypeData?.error) {
      const mappedList = orgTypeData.records.map((item) => ({
        orgType: item.name,
        orgId: item.id,
      }));
      yield put({ type: constant.FETCH_ORGTYPE_SUCCESS, data: mappedList });
    } else {
      yield setAuthError(orgTypeData.message);
    }
  } catch (e) {
    yield setAuthError(e.message);
    console.error(e);
  }
}
function* getMenuList() {
  try {
    const menuResponse = yield SettingAPI.getMenuList(),
    menuItems = menuResponse;
    const menuData = menuItems.data;
    if (!menuData?.error) {
      const mappedMenuItems = menuData.records.map((menuItem) => ({
        id: menuItem.id,
        name: menuItem.menuName,
        path: menuItem.menuUrl,
        element: menuItem.menuComponent,
      }));
      yield put({ type: constant.FETCH_MENULIST_RES, data: mappedMenuItems });
    } else {
      yield setAuthError(menuData.message);
    }
  } catch (e) {
    yield setAuthError(e.message);
    console.error(e);
  }
}

function* getMenuRoleBasedList(roleId) {
  try {
    const menuList = yield SettingAPI.getRoleBaseMenuList(roleId),
    menuListResponse = menuList;
    if(menuListResponse === undefined) return;
    const menuListData = menuListResponse.data;
    if (!menuListData?.error) {
      const mappedMenuList = menuListData.records.map((menuList) => ({
        id: menuList.id,
        button_operation: menuList.button_operation,
        menu_id: menuList.menu_id,
        role_id: menuList.role_id,
        status: menuList.status,
      }));
      yield put({
        type: constant.FETCH_ROLE_MENULIST_RES,
        data: mappedMenuList,
      });
    } else {
      yield setAuthError(menuListData.message);
    }
  } catch (e) {
    yield setAuthError(e.message);
    console.error(e);
  }
}

function* adminAddRole(params) {
  try {
    const roleName = params.roleName;
    const roleResponse = yield SettingAPI.addNewRole(roleName),
    roleItems = roleResponse;
    if(roleItems === undefined) return;
    const roleData = roleItems.data;
    if (!roleData?.error) {
      const mappedRoleItem = roleData.records.map((roleItem) => ({
        id: roleItem.id,
        name: roleItem.name,
      }));
      toast.success("Role added successfully", {
        toastId: 'showToast',
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1500, 
      });
      yield put({ type: constant.FETCH_ROLE_REQUEST, data: "" });
    } else {
      yield setAuthError(roleData.message);
    }
  } catch (e) {
    yield setAuthError(e.message);
    console.error(e);
  }
}
function* adminAddMenu(params) {
  try {
    const menuName = params.menuName;
    let componentName;
    switch (menuName) {
      case "Settings":
        componentName = "Settings";
        break;
      case "User":
        componentName = "User";
        break;
      case "Organization":
        componentName = "Organization";
        break;
      default:
        componentName = "Dashboard";
    }
    const menuResponse = yield SettingAPI.addNewMenu(menuName, componentName),
    menuItems = menuResponse;
    const menuData = menuItems;
    if(menuData === undefined) return;
    if (!menuData?.error) {
      toast.success("Menu added successfully", {
        toastId: 'showToast',
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1500, 
      });
      yield put({ type: constant.FETCH_MENULIST_REQ, data: "" });
    } else {
      yield setAuthError(menuData.message);
    }
  } catch (e) {
    yield setAuthError(e.message);
    console.error(e);
  }
}

function* adminDeleteRole(params) {
  const roleName = params.roleName
  try {
    const roleId = params.roleId;
    const rolesResponse = yield SettingAPI.deleteRole(roleId),
    roleItems = rolesResponse;
    if(roleItems === undefined) {
      yield setAuthError(`Please unmap the menus associated with ${roleName} to delete. Go to assign setup`);
      return
    };
    const roleData = roleItems.data;
    if (!roleData?.error) {
      toast.success("Role deleted successfully!!", {
        toastId: 'showToast',
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1500, 
      });
      yield put({ type: constant.FETCH_ROLE_REQUEST, data: "" });
    } else {
      yield setAuthError(roleData.message);
    }
  } catch (e) {
    yield setAuthError(e.message);
    console.error(e);
  }
}

function* adminDeleteMenu(params) {
  const menuName = params.menuName
  try {
    const menuId = params.menuId;
    const menuResponse = yield SettingAPI.deleteMenu(menuId, menuName),
    menuItems = menuResponse;
    if(menuItems === undefined) {
      yield setAuthError(`Please remove menu mapping under ${menuName} to delete menu. Go to assign setup to update the settigs`);
      return
    };
    const menuData = menuItems.data;
    if (!menuData?.error) {
      toast.success("Menu deleted successfully!!", {
        toastId: 'showToast',
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1500,
      });
      yield put({ type: constant.FETCH_MENULIST_REQ, data: "" });
      yield put({ type: constant.FETCH_MENU_REQ, data: "" });
    } else {
      yield setAuthError(menuData.message);
    }
  } catch (e) {
    yield setAuthError(e.message);
    console.error(e);
  }
}

function* adminEditRole(params) {
  try {
    const { roleId, editedRole } = params.payload;
    const rolesResponse = yield SettingAPI.editRole(roleId, editedRole),
    roleItems = rolesResponse;
    if(roleItems === undefined) return;
    const roleData = roleItems.data;
    if (!roleData?.error) {
      toast.success("Role updated successfully!!", {
        toastId: 'showToast',
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1500,
      });
      yield put({ type: constant.FETCH_ROLE_REQUEST, data: "" });
    } else {
      yield setAuthError(roleData.message);
    }
  } catch (e) {
    yield setAuthError(e.message);
    console.error(e);
  }
}
function* adminEditMenu(params) {
  try {
    const { menuId, menuName , menuComponent, menuUrl} = params.payload;
    const roleResponse = yield SettingAPI.editMenu(menuId, menuName, menuComponent, menuUrl),
    roleItems = roleResponse;
    if(roleItems === undefined) return;
    const roleData = roleItems.data;
    if (!roleData?.error) {
      toast.success("Menu updated successfully!!", {
        toastId: 'showToast',
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1500, 
      });
      yield put({ type: constant.FETCH_MENULIST_REQ, data: "" });
      yield put({ type: constant.FETCH_MENU_REQ, data: "" });
    } else {
      yield setAuthError(roleData.message);
    }
  } catch (e) {
    yield setAuthError(e.errors);
    console.error(e);
  }
}

function* assignMenuRole(param) {
  try {
    const assignResponse = yield SettingAPI.assignMenuRole(param.data),
    assignMenu = assignResponse;
    if(assignMenu === undefined) return;
    const assignData = assignMenu.data
    if (!assignData?.error) {
      toast.success("Assign Menu item has been updated successfully!!", {
        toastId: 'showToast',
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1500, 
      });
    } else {
      yield setAuthError(assignData.message);
    }
  } catch (e) {
    yield setAuthError(e.message);
    console.error(e);
  }
}

function* adminAddOrgType(params) {
  try {
    const orgType = params.orgType;
    const response = yield SettingAPI.addNewOrgType(orgType),
    items = response;
    if(items === undefined) return;
    const itemsData = items.data;
    if (!itemsData?.error) {
      toast.success("Org Type added successfully", {
        toastId: 'showToast',
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1500, 
      });
      yield put({ type: constant.FETCH_ORGTYPE_REQUEST, data: "" });
    } else {
      yield setAuthError(itemsData.message);
    }
  } catch (e) {
    yield setAuthError(e.message);
    console.error(e);
  }
}

function* adminEditOrgType(params) {
  try {
    const { orgId, editedOrgType } = params.payload;
    const roleResponse = yield SettingAPI.editOrgType(orgId, editedOrgType),
    roleItems = roleResponse;
    const roleData = roleItems;
    if(roleData === undefined) return;
    if (!roleData?.error) {
      toast.success("OrgType updated successfully!!", {
        toastId: 'showToast',
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1500,
      });
      yield put({ type: constant.FETCH_ORGTYPE_REQUEST, data: "" });
    } else {
      yield setAuthError(roleData.message);
    }
  } catch (e) {
    yield setAuthError(e.message);
    console.error(e);
  }
}

function* adminDeleteOrgType(params) {
  debugger
  const orgType = params.orgType;
  try {
    const orgId = params.orgId;
    
    const menuResponse = yield SettingAPI.deleteOrgType(orgId),
  
    menuItems = menuResponse;
    if(menuItems === undefined) {
      yield setAuthError(`Please unmap the roles associated with ${orgType} to delete. Select the org type to see the mapped roles`);
      return
    };
    const menuData = menuItems.data;
    if (!menuData?.error) {
      toast.success("OrgType deleted successfully!!", {
        toastId: 'showToast',
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1500,
      });
      yield put({ type: constant.FETCH_ORGTYPE_REQUEST, data: "" });
    } else {
      yield setAuthError(menuData.error);
    }
  } catch (e) {
    yield setAuthError(e.message);
    console.error(e);
  }
}

function* getOrgTypeMenuRoleList(orgId) {
  try {
    const response = yield SettingAPI.getOrgTypeRoleList(orgId),
    menuResponse = response;
    if(menuResponse === undefined) return;
    const menuData = menuResponse.data;
    if (menuResponse.status === 200 && !menuData?.error) {
      const mappedRoleList = menuData.records.map((role) => ({
        id: role.id,
        roleName: role.roleName,
        roleId: role.roleId,
        orgTypeName: role.orgTypeName,
      }));
      yield put({
        type: constant.FETCH_ORGTYPE_MENULIST_RES,
        data: mappedRoleList,
      });
    } else {
      yield setAuthError(menuData.message);
    }
  } catch (e) {
    yield setAuthError(e.message);
    console.error(e);
  }
}

function* assignOrgTypeRole(param) {
  try {
    const assignResponse = yield SettingAPI.assignOrgTypeRole(param.data),
    assignMenu = assignResponse;
    if(assignMenu === undefined) return;
    const assignData = assignMenu.data;
    if (!assignData?.error) {
      toast.success("Assign Role item has been updated successfully!!", {
        toastId: 'showToast',
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1500,
      });
    } else {
      yield setAuthError(assignData.error);
    }
  } catch (e) {
    yield setAuthError(e.response.data.errors);
    console.error(e);
  }
}

function* setThemeAndLogo(params) {
  debugger
  try {
    const response = yield SettingAPI.setThemeAndLogo(params),
    responseItems = response;
    if(responseItems === undefined) return;
    const themeLogoRespData = responseItems.data;
    console.log(themeLogoRespData, "themeLogoRespData 123")
    if (!themeLogoRespData?.error) {
      toast.success("Organisation Settings Updated Successfully", {
        toastId: 'showToast',
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1500,
      });
      yield put({ type: constant.SET_THEME_LOGO_RES, data: themeLogoRespData });
    } else {
      yield setAuthError(themeLogoRespData.message);
    }
  } catch (e) {
    yield setAuthError(e.message);
    console.error(e);
  }
}

function* settingSaga() {
  yield takeEvery(constant.FETCH_ROLE_REQUEST, getRoleList);
  yield takeEvery(constant.FETCH_ROLE_MENULIST_REQ, function* (action) {
    yield getMenuRoleBasedList(action.data);
  });
  yield takeEvery(constant.FETCH_MENULIST_REQ, getMenuList);
  yield takeEvery(constant.ADMIN_ADD_ROLE, function* (action) {
    yield adminAddRole(action.data);
  });
  yield takeEvery(constant.ADMIN_DELETE_ROLE_REQ, function* (action) {
    yield adminDeleteRole(action.data);
  });
  yield takeEvery(constant.ADMIN_EDIT_ROLE_REQ, function* (action) {
    yield adminEditRole(action.data);
  });

  yield takeEvery(constant.ADMIN_ADD_MENU, function* (action) {
    yield adminAddMenu(action.data);
  });

  yield takeEvery(constant.ADMIN_DELETE_MENU_REQ, function* (action) {
    yield adminDeleteMenu(action.data);
  });
  yield takeEvery(constant.ADMIN_EDIT_MENU_REQ, function* (action) {
    yield adminEditMenu(action.data);
  });
  yield takeEvery(constant.MENU_ASSIGN_ROLE, assignMenuRole);
  yield takeEvery(constant.FETCH_ORGTYPE_REQUEST, getOrgTypeList);

  yield takeEvery(constant.ADMIN_ADD_ORGTYPE, function* (action) {
    yield adminAddOrgType(action.data);
  });
  yield takeEvery(constant.ADMIN_EDIT_ORGTYPE_REQ, function* (action) {
    yield adminEditOrgType(action.data);
  });
  yield takeEvery(constant.ADMIN_DELETE_ORGTYPE_REQ, function* (action) {
    yield adminDeleteOrgType(action.data);
  });
  yield takeEvery(constant.FETCH_ORGTYPE_MENULIST_REQ, function* (action) {
    yield getOrgTypeMenuRoleList(action.data);
  });
  yield takeEvery(constant.ORGTYPE_ASSIGN_ROLE, assignOrgTypeRole);
  yield takeEvery(constant.SET_THEME_LOGO_REQ, function* (action) {
    yield setThemeAndLogo(action.data) });
  
}

export default settingSaga;
