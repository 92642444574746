import produce from "immer";
import { getToken } from "../Utils";
import { constant } from "../constants";

// ==============================|| REDUCER AUTH ||============================== //

const initState = () => ({
  jwToken: "",
  isAuthenticated: false,
  userInfo: {},
  routerData: [],
  menuItems: [],
  menuList: [],
  isLoading: false,
  isEnableredirect: true,
  errors: [],
});

export default (state = initState(), action) => {
  switch (action.type) {
    case constant.AUTH_INIT:
      return { ...state, isAuthenticated: true };
    case constant.AUTH_ERRORS:
      return { ...state, errors: action.data };
    case constant.AUTH_CLEAN:
      return {
        ...state,
        userInfo: {},
        jwToken: "",
        errors: [],
        isAuthenticated: false,
      };
    case constant.FETCH_ROUTER_REQUEST:
      return { ...state, errors: [], isLoading: true };
    case constant.FETCH_ROUTER_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        menuItems: action.data,
        routerData: action.data,
        errors: [],
      };
    case constant.FETCH_ROUTER_FAILURE:
      return { ...state, isLoading: false };
    case constant.ENREDIRECT_ACTION:
      return { ...state, isEnableredirect: false };
    case constant.RESET_AUTH_STATE_INIT:
      return initState();
    case constant.FETCH_MENU_REQ:
      return { ...state, errors: [], isLoading: true };
    case constant.FETCH_MENU_RES:
      return { ...state, menuList: action.data, errors: [] };
    default:
      return { ...state };
  }
};
