import { createTheme } from "@mui/material";

const colors = {
  white: '#FFFFFF',
  primary: '#5569ff',
  black: '#00000',
  secondary: '#6E759F',
  grey: '#333333',
  dividerBg: '#F6F8FB',

  shadows: {
    cardSm: '0px 2px 3px rgba(159, 162, 191, 0.18), 0px 1px 1px rgba(159, 162, 191, 0.32)',
    sidebar: '2px 0 3px rgba(159, 162, 191, 0.18), 1px 0 1px rgba(159, 162, 191, 0.32)',
  },
}
const theme = createTheme({
  palette: {
    background: {
      default: 'blue',
      paper: 'white',
    },
    boxShadow : 'rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px !important',
    border: '1px solid #e1e1e1 !important',
    primary: {
      contrastText: 'red',
      main: '#5664d2'
    },
    text: {
      primary: '#172b4d',
      secondary: '#6b778c'
    }
  },
  header: {
    height: '64px',
    background: colors.white,
    boxShadow: colors.shadows.cardSm,
    textColor: colors.primary,
    width: '80vw'
  },
  sidebar: {
    width: '20vw',
    background: colors.white,
    textColor: colors.secondary,
    boxShadow: colors.sidebar,
    dividerBg: colors.dividerBg
  },
  spacing: 8,
});
export function themeCreator(value) {
  return theme;
}