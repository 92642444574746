import APIService from "./api";
import { getUserInfo } from "../Utils";
import * as constants from "./api.constant";

const MainAPI = {
  getMenuList() {
    return APIService.get(constants.API_MENU_ALL, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }).catch((err) => {
      throw err;
    });
  },
  getOrgUserList(userId, top, skip) {
    return APIService.get(
      constants.API_GET_ADMIN_ORG_USER,
      {
        params: {
          loginId: userId,
          top: top,
          skip: skip,
        },
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    ).catch((err) => {
      throw err;
    });
  },
  getOrgList() {
    let url = "";
    const userinfo = getUserInfo();
    if (userinfo.roleId === 1) {
      url = constants.API_GET_ORG;
    } else {
      url = constants.API_GET_ORG_ID + "?id=" + userinfo.roleId;
    }
    return APIService.get(url, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }).catch((err) => {
      throw err;
    });
  },
  updateOrganization(data) {
    return APIService.put(constants.API_UPDATE_ORGANIZATION, data, {
      headers: {
        "Content-Type": "application/json",
      },
    }).catch((err) => {
      throw err;
    });
  },
  updateUser(data) {
    return APIService.put(constants.API_UPDATE_USER, data, {
      headers: {
        "Content-Type": "application/json",
      },
    }).catch((err) => {
      throw err;
    });
  },
  createOrganization(data) {
    return APIService.post(constants.API_CREATE_ORGANIZATION, data, {
      headers: {
        "Content-Type": "application/json",
      },
    }).catch((err) => {
      throw err;
    });
  },
  createUser(data) {
    return APIService.post(constants.API_CREATE_USER, data,
      {
      headers: {
        "Content-Type": "application/json",
      },
    }).catch((err) => {
      throw err;
    });
  },
  deleteUser(userId) {
    return APIService.delete(constants.API_DELETE_USER, 
      {
        params: {
          userId: userId
        },
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }).catch((err) => {
        throw err;
      });
  },
  deleteOrg(orgId) {
    return APIService.delete(constants.API_DELETE_ORGANIZATION,
      {
        params: {
          id: orgId
        }
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }).catch((err) => {
        throw err;
      });
  },

  getRoleForOrgType(orgTypeID) {
    return APIService.get(constants.API_GET_ROLES_FOR_ORGTYPE,
    {
      params: {
        orgId: orgTypeID
      }
    }, 
    {
      headers: {
        "Content-Type": "application/json",
      },
    }).catch((err) => {
      throw err;
    });
  },

  getOrgTypes() {
    return APIService.get(constants.API_GET_ORG_TYPES, {
      headers: {
        "Content-Type": "application/json",
      },
    }).catch((err) => {
      throw err;
    });
  },
};

export default MainAPI;
