import { put, takeEvery } from "redux-saga/effects";
import MainAPI from "../services/main.service";
import { constant } from "../constants";
import { responseMessageSave } from "../Utils";
import { showError } from "../Utils/helper";
import { toast } from "react-toastify";
import { getOrgdata } from "../store/userAction";

// ==============================|| SAGA MIDDLEWARE ORGANIZATION ||============================== //

function* setOrganizationError(data) {
  showError(data);
  return false;
}

function* getOrgList(params) {
  try {
    const response = yield MainAPI.getOrgList(),
    orgResponse = response;
    if(orgResponse === undefined) return;
    const orgData = orgResponse.data;
    if (!orgData?.error) {
      yield put({ 
        type: constant.SET_ORG_DATA, 
        data: orgData.records 
      });
    } else {
      yield setOrganizationError(orgData.message);
    }
  } catch (e) {
    yield setOrganizationError("error", e.message);
  }
}
function* updateOrganization(params) {
  try {
    const response = yield MainAPI.updateOrganization(params.data),
    orgReturn = response;
    if(orgReturn === undefined) return;
    const updateData = orgReturn.data;
    if (!updateData?.error) {
      responseMessageSave();
      yield put({
        type: constant.ORGCURD_DATA_SUCCESS,
        data: updateData.message,
      });
    } else {
      yield setOrganizationError(updateData.message);
    }
  } catch (e) {
    yield setOrganizationError(e.message);
    console.error(e);
  }
}

function* createOrganization(params) {
  try {
    const response = yield MainAPI.createOrganization(params.data),
    orgReturn = response;
    if(orgReturn === undefined) return;
    const orgData = orgReturn.data;
    if (!orgData?.error) {
      responseMessageSave();
      yield put({
        type: constant.ORGCURD_DATA_SUCCESS,
        data: orgData.message,
      });
    } else {
      yield setOrganizationError(orgData.message);
    }
  } catch (e) {
    yield setOrganizationError(e.message);
    console.error(e);
  }
}

function* deleteOrganization(params) {
  try{
    const response = yield MainAPI.deleteOrg(params.data),
    deleteOrgReturn = response;
    if(deleteOrgReturn === undefined) return;
    const dOrgData = deleteOrgReturn.data;
    if(!dOrgData?.error){
      toast.success("Ogranization deleted successfully", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1500, // milliseconds
      });      
      responseMessageSave();
      yield put(getOrgdata());
    }
    else{
      yield setOrganizationError(dOrgData.message);
    }
  }
  catch(e){
    yield setOrganizationError(e.message);
    console.error(e);
  }
}

function* getOrgTypes() {
  try {
    const response = yield MainAPI.getOrgTypes(),
    orgResponse = response;
    if(orgResponse === undefined) return;
    const orgTypesData = orgResponse.data;
    if (!orgTypesData?.error) {
      yield put({ type: constant.SET_ORG_TYPES, data: response.data.records });
    } else {
      yield setOrganizationError(orgTypesData.message);
    }
  } catch (e) {
    yield setOrganizationError(e.message);
    console.error(e);
  }
}

function* resetCurdResponse() {
  try {
    yield put({ type: constant.INIT_RESET_ORGCURD_RESPONSE, data: "" });
  } catch (e) {
    yield setOrganizationError(e.message);
    console.error(e);
  }
}

function* organizationSaga() {
  yield takeEvery(constant.GET_ORG_DATA, getOrgList);
  yield takeEvery(constant.UPDATE_ORG_DATA, updateOrganization);
  yield takeEvery(constant.CREATE_ORG_DATA, createOrganization);
  yield takeEvery(constant.RESET_ORGCURD_RESPONSE, resetCurdResponse);
  yield takeEvery(constant.GET_ORG_TYPES, getOrgTypes);
  yield takeEvery(constant.ADMIN_DELETE_ORG, deleteOrganization);
}

export default organizationSaga;
