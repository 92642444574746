import { constant } from "../constants";

const initState = () => ({
  isAuthenticated: true,
  errors: [],
  themeState: '',
  languageState: '' 
});

export default (state = initState(), action) => {
  
  switch (action.type) {
    
    case constant.RESET_STATE_INIT:
      return { ...state, errors: [], isLoading: true };
    case constant.CHANGE_THEME:
      return { ...state, themeState: action.data };
    case constant.CHANGE_LANGUAGE:
      return { ...state, languageState: action.data };
    default:
      return state;
  }
};
