import React from 'react';
import { Navigate } from "react-router-dom";
import CryptoJS from "crypto-js";
import { toast } from 'react-toastify';
import ReactGA from 'react-ga4';

export const passEncrypt = (data, callback) => {
    return CryptoJS.MD5(data).toString();
}

export const showError = (message) => {
    toast.error(message);
};

const validateUser = (parent) => {
    if (true) {
        return <parent.component />
    } else {
        console.log('User doesnt have access to the page, redirecting to home...')
        return <Navigate replace to='/' />
    }
}

export const getElement = (parent) => {
    return parent.isProtected ? validateUser(parent) : <parent.component />
}

export const GAEvent = (category, action, label) => {
    ReactGA.event({
        category: category,
        action: action,
        label: label,
    });
}

export const GAPageView = (page, title) => {
    ReactGA.send({ 
        hitType: "pageview", 
        page: page, 
        title: title 
    });
}

export const segmentCapture = (actionType, actionDetails) => {
    switch(actionType){
        case 'trackEvent':
            window.analytics.track(actionDetails)
            break
        case 'pageView':
            window.analytics.page(actionDetails.category, actionDetails.name, actionDetails.params)
            break
        case 'identify':
            window.analytics.identify(actionDetails.userID, actionDetails.params)
            break
        case 'logout':
            window.analytics.track("Logout button click")
            window.analytics.reset()
            break
        default:
            break
    }
}

export const getLanguage = () =>{
    return window.localStorage.getItem('languageMode');
}

export const saveLanguage = (data) => {
    window.localStorage.setItem('languageMode',data);
};

export const getTheme = () =>{
    return window.localStorage.getItem('themeMode');
}

export const saveTheme = (data) => {
    return window.localStorage.setItem('themeMode',data);
};

export const toCamelCase = (input) => {
    return input
      .toLowerCase() 
      .split(/[\s_]+/) 
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) 
      .join(' '); 
  }