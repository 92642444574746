const FONT_PRIMARY = 'Roboto Flex';

const typography = {
  fontFamily: FONT_PRIMARY,
  fontWeightRegular: 400,
  fontWeightMedium: 600,
  fontWeightBold: 700,
  h1: {
    fontWeight: 700,
    lineHeight: 80 / 64,
    fontSize: '36px',
  },
  h2: {
    fontWeight: 700,
    lineHeight: '35.16px',
    fontSize: '30px',
  },
  h3: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: '24px',
  },
  h4: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: '20px',
  },
  h5: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: '18px',
  },
  h6: {
    fontWeight: 700,
    lineHeight: 28 / 18,
    fontSize: '16px',
  },
  
  subtitle1: {
    fontWeight: 500,
    lineHeight: '23.44px',
    fontSize: '20px',
  },
  subtitle2: {
    fontWeight: 600,
    lineHeight: 22 / 14,
    fontSize: '14px',
  },
  subtitle3:{
    fontWeight: 600,
    fontSize: '14px',
  },
  subtitle4:{
    fontWeight: 600,
    fontSize: '16px',
  },
  body1: {
    lineHeight: 1.5,
    fontSize: '14px',
  },
  body2: {
    fontSize: '14px',
    fontWeight: 400,
  },
  body3: {
    fontSize: '16px',
    fontWeight: 400,
  },
  caption: {
    lineHeight: 1.5,
    fontSize: '12px',
  },
  fieldTitle: {
    fontWeight: 400,
    fontSize: '16px'
  },
  fieldSubTitle: {
    fontWeight: 400,
    fontSize: '12px',
  },
  fieldSubTitle1: {
    fontWeight: 700,
    fontSize: '12px',
  },
  fieldSubTitle2: {
    fontWeight: 500,
    lineHeight: 1.5,
    fontSize: '20px',
  },
  fieldSubTitle3: {
    fontWeight: 600,
    lineHeight: 1.5,
    fontSize: '20px',
  },
  overline: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: '12px',
    letterSpacing: 1.1,
    textTransform: 'uppercase',
  },
  button: {
    fontWeight: 700,
    lineHeight: 24 / 14,
    fontSize: '14px',
    textTransform: 'none',
  },
};

export default typography;
