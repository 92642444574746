import produce from "immer";
import { constant } from "../constants";
import orgTypeList from "../mock/orgTypeList";

// ==============================|| REDUCER ORGANIZATION ||============================== //

const initState = () => ({
  errors: [],
  orgData: [],
  orgTypes: [],
  orgresponse: {},
});

export default (state = initState(), action) => {
  let data = [];
  switch (action.type) {
    case constant.ORGANIZATION_ERRORS:
      return { ...state, errors: action.data };
    case constant.INIT_RESET_ORGCURD_RESPONSE:
      return { ...state, orgresponse: {} };
    case constant.ORGCURD_DATA_SUCCESS:
      return { ...state, orgresponse: { success: true, message: action.data } };
      break;
    case constant.SET_ORG_DATA:
      action.data.map((ele, indx) => {
        return data.push({
          id: indx + 1,
          orgname: ele.orgname,
          name: ele.firstname + " " + ele.lastname,
          firstname: ele.firstname,
          orgtype: ele.orgtype,
          email: ele.email,
          business: ele.business,
          action: "",
          org_id: ele.id,
          additional_info: ele.additional_info,
          street: ele.street,
          city: ele.city,
          state: ele.state,
          zipcode: ele.zipcode,
          country: ele.country,
          phonenumber: ele.phonenumber,
          logoUrl:ele.logoUrl,
          colorCode:ele.colorCode,
          themeName:ele.themeName,
          primaryColor:ele.primaryColor,
          secondaryColor:ele.secondaryColor
        });
      });
      return { ...state, orgData: data };
    case constant.SET_ORG_TYPES:
      return { ...state, orgTypes: action.data };
    case constant.RESET_ORG_STATE_INIT:
      return initState();
    default:
      return { ...state };
  }
};
