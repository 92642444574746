import APIService from "./api";
import mockService from "./mockService"
import { API_LOGIN } from "./api.constant";
import { API_MENU_ALL_LIST } from "./api.constant";
import { getUserInfo } from "../Utils";

const AuthAPI = {
  login(username, password) {
    let data = {
        username:username,
        password:password
    }
    const endpoint = process.env.REACT_APP_MOCK_ENABLED === true ? mockService : APIService;
 
    return endpoint.post(API_LOGIN, data, {
      headers: {
        "Content-Type": "application/json",
      },
    }).catch((err) => {
      throw err;
    });
  },
  getMenuList(payload) {
    const userinfo = getUserInfo();
    let url
    if (payload.data.langugage){
      // debugger
       url = API_MENU_ALL_LIST + "?roleId=" + userinfo.roleId + "&langCode=" + payload.data.langugage;
    } else {
       url = API_MENU_ALL_LIST + "?roleId=" + userinfo.roleId;
    }
    
    
    return APIService.get(url, {
      headers: {
        "Content-Type": "application/json",
      },
    }).catch((err) => {
      throw err;
    });
  },
};

export default AuthAPI;