import { put, takeEvery } from "redux-saga/effects";
import AuthAPI from "../services/auth.service";
import { saveToken, deleteToken } from "../Utils";
import { constant } from "../constants";
import { showError } from "../Utils/helper";
import { toast } from "react-toastify";

// ==============================|| SAGA MIDDLEWARE AUTH ||============================== //

function* setAuthError(type, data) {
  yield deleteToken();
  yield put({ type: constant.AUTH_CLEAN });
  yield put({ type: type, data });
  showError(data);
  return false;
}

function* authLogin(params) {
  try {
    const { username, password, defaultLanguage } = params.payload;
    const response = yield AuthAPI.login(username, password, defaultLanguage),
    authResponse = response;
    if(authResponse === undefined) return;
    const userInfo = authResponse.data;
    yield deleteToken();
    yield put({ type: constant.AUTH_CLEAN });
    if (!userInfo?.error) {
      yield put({ type: constant.AUTH_INIT, data: userInfo });
      yield saveToken(userInfo.accessToken, username, userInfo.refreshToken);
      yield put({ type: constant.FETCH_MENU_REQ, data: userInfo });
    } else {
      yield setAuthError(constant.AUTH_ERRORS, userInfo.message);
    }
  } catch (e) {
    yield setAuthError(constant.AUTH_ERRORS, e.message);
    console.error(e);
  } finally {
  }
}

function* getMenuList(data) {

  try {
    const menuResponse = yield AuthAPI.getMenuList(data),
    menuItems = menuResponse;
    
    if(menuItems === undefined) return;
    const menuData = menuItems.data;
    if (!menuData?.error) {
      const mappedMenuItems = menuData.records.map((menuItem) => ({
        id: menuItem.menu_id,
        name: menuItem.lang_name,
        path: menuItem.menuurl,
        element: menuItem.component,
        buttonOperation: menuItem.button_operation,
      }));
      if(mappedMenuItems.length === 0){
        toast.info("Menu list not mapped for the user")
      }
      yield put({ type: constant.FETCH_ROUTER_SUCCESS, data: mappedMenuItems });
    } else {
      yield setAuthError(menuData.message);
    }
  } catch (e) {
    yield setAuthError(e.message);
    console.error(e);
  }
}

function* checkAuthDetail(params) {
  if (params.payload.isLoggedIn) {
    yield put({ type: constant.AUTH_CLEAN });
  } else {
    yield put({ type: constant.AUTH_INIT });
  }
}

function* changeRedirct(param) {
  yield put({ type: constant.ENREDIRECT_ACTION, data: param });
}

function* authSaga() {
  yield takeEvery(constant.AUTH_LOGIN, function* (action) {
    yield authLogin(action.data);
  });
  yield takeEvery(constant.AUTH_VERIFICATION, checkAuthDetail);
  yield takeEvery(constant.ENREDIRECT_INIT, changeRedirct);
  yield takeEvery(constant.FETCH_MENU_REQ, getMenuList);
}

export default authSaga;
