import { put, takeEvery } from "redux-saga/effects";
import MainAPI from "../services/main.service";
import { constant } from "../constants";
import { showError } from "../Utils/helper";
import { responseMessageSave } from "../Utils";
import { toast } from "react-toastify";

// ==============================|| SAGA MIDDLEWARE User ||============================== //

function* setUserError(data) {
  showError(data);
  return false;
}

function* getUserList(params) {
  try {
    const { userId, top, skip } = params.payload;
    const response = yield MainAPI.getOrgUserList(userId, top, skip),
    userResponse = response;
    if(userResponse === undefined) return;
    const userData = userResponse.data
    if(!userData?.error){
      yield put({ type: constant.SET_USER_DATA, data: userData });
    }
    else{
      yield setUserError(userData.message);
    }
  } catch (e) {
    yield setUserError(e.message);
    console.error(e);
  }
}
function* updateUser(params) {
  try {
    const response = yield MainAPI.updateUser(params.data),
    userReturn = response;
    if(userReturn === undefined) return;
    const userData = userReturn.data;
    if (!userData?.error) {
      toast.success("User updated successfully", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1500, 
      });
      yield put({
        type: constant.USERCURD_DATA_SUCCESS,
        data: userData.message,
      });
    } else {
      yield setUserError(userData.message);
    }
  } catch (e) {
    yield setUserError(e.message);
    console.error(e);
  }
}

function* createUser(params) {
  try {
    const userResponse = yield MainAPI.createUser(params.data),
    userReturn = userResponse;
    if(userReturn === undefined) return;
    const userData = userReturn.data;
    if (!userData?.error) {
      responseMessageSave();
      yield put({
        type: constant.USERCURD_DATA_SUCCESS,
        data: userData.message,
      });
    } else {
      yield setUserError(userData.message);
    }
  } catch (e) {
    yield setUserError(e.message);
    console.error(e);
  }
}
function* deleteUser(params) {
  try {
    const userId = params.data.payload.userId;
    const response = yield MainAPI.deleteUser(userId),
    orgReturn = response;
    if(orgReturn === undefined) return;
    const orgData = orgReturn.data;
    if (!orgData?.error) {
      toast.success("User deleted successfully", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1500, 
      });
      const userInfo = JSON.parse(window.localStorage.getItem("userInfo"));
      yield put({
        type: constant.GET_USER_DATA,
        data: {
          payload: { userId: userInfo.userId, top: 100, skip: 0 },
        }
      });
    } else {
      yield setUserError(orgData.message);
    }
  } catch (e) {
    yield setUserError(e.message);
    console.error(e);
  }
}

function* getRolesForOrgType(params) {
  try {
    const rolesResponse = yield MainAPI.getRoleForOrgType(params.data),
    rolesReturn = rolesResponse;
    if(rolesReturn === undefined) return;
    const rolesData = rolesReturn.data;
    if (!rolesData?.error) {
      yield put({
        type: constant.SET_ROLES_FOR_ORGTYPE,
        data: rolesData.records,
      });
    } else {
      yield setUserError(rolesData.message);
    }
  } catch (e) {
    yield setUserError(e.message);
    console.error(e);
  }
}

function* resetCurdResponse() {
  yield put({ type: constant.INIT_RESET_ORGCURD_RESPONSE, data: "" });
}

function* userSaga() {
  yield takeEvery(constant.GET_USER_DATA, function* (action) {
    yield getUserList(action.data);
  });
  yield takeEvery(constant.UPDATE_USER_DATA, updateUser);
  yield takeEvery(constant.CREATE_USER_DATA, createUser);
  yield takeEvery(constant.RESET_USERCURD_RESPONSE, resetCurdResponse);
  yield takeEvery(constant.GET_ROLES_FOR_ORGTYPE, getRolesForOrgType);
  yield takeEvery(constant.ADMIN_DELETE_USER_REQ, deleteUser);
}

export default userSaga;
